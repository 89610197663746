.pdev-container {
  background: linear-gradient(
    to right,
    rgba(0, 166, 254, 0.25),
    rgba(0, 166, 254, 0.25) 0%,
    #fff 100%
  );
}
.article-faded-bg {
  border-radius: 20px;
  background: linear-gradient(
    0deg,
    rgba(22, 43, 62, 0.51) 0%,
    rgba(22, 43, 62, 0.51) 100%
  );
}
