.heroheader h1 {
  color: var(--Dark-Black, #162b3e);
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.11px;
  max-width: 808px;
  height: 54px;
  flex-shrink: 0;
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: white;
}

.swiper-pagination-bullet-active {
  background: rgb(
    49,
    48,
    59
  ); /* Or any other color you'd like for the active bullet */
}
.swiper-button-prev,
.swiper-button-next {
  height: 30px;
  width: 30px;
  color: white;
  background-color: black;
  border-radius: 50%;
}
.mySwiper .swiper-button-next:after,
.mySwiper .swiper-button-prev:after {
  font-size: 15px;
}
.swiper-button-prev {
  transform: translateX(-9px);
}
.swiper-button-next {
  transform: translateX(9px);
}
