.program-button {
  border-radius: 2px;
  border: 4px solid rgba(0, 166, 254, 0.25);
  background: var(--Backgrounds-Primary, #fff);
  min-width: 185px;
  height: 54px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px;
}
.program-container {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.27);
  background: var(--Dark-Black, #162b3e);
  display: flex;
  min-height: 228px;
  height: auto;
  padding: 20px;
  padding-top: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  color: white;
}

.p-dev-heading {
  color: var(--Dark-Black, #162b3e);
  text-align: center;
  text-shadow: 0px 4px 11px rgba(0, 166, 254, 0.25);
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.18px;
}
.p-dev-text {
  color: var(--Dark-Black, #162b3e);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
  letter-spacing: 0.16px;
  max-width: 843px;
}
.program-logo {
  width: 192px;
  height: 143px;
}
