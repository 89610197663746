@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "DM Sans", serif;
  scrollbar-width: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dark-background {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    var(--Dark-Black, #162b3e);
}
.dark-text {
  color: var(--Dark-Black, #162b3e);
}
.light-blue-shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 166, 254, 0.25);
}
@media only screen and (max-width: 1400px) {
  * {
    overflow-x: hidden;
  }
}
