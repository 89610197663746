.heading-h1 {
  color: #294a70;

  font-family: "DM Sans";

  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-left: 1px solid rgba(22, 43, 62, 0.5);
  letter-spacing: 0.9px;
}
.hover-cyan:hover {
  color: rgb(0, 221, 250);
  transition: 1s ease-in-out;
}
.header-button {
  border-radius: 10px;
  background: var(--Backgrounds-Primary, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 166, 254, 0.25);
}

.header-button {
  display: none;
}
.page-option-with-dropdown {
  z-index: 9999;
}
.page-option-with-dropdown:hover .header-button {
  display: block;
}
.dropdown-item {
  text-align: left;
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.05px dotted #00a5fe52;
  background-color: white;
  text-align: left;
  font-size: 14px;
  color: #162b3e;
  padding: 5px 8px;
}
.dropdown-item:hover {
  background-color: #162b3e;
  color: white;
  border-bottom: none;
}

.footer-container {
  background: rgba(22, 43, 62, 0.41);
}
.login-button:hover {
  background-color: linear-gradient(103deg, #00a6fe -15.8%, #162b3e 106.09%);
  transition: 1s ease-in-out;
}
