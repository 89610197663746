.heading-details {
  color: var(--title--text-color, #f5f5f5);
  text-align: justify;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
  letter-spacing: 0.16px;
}
.heading-title {
  color: var(--Foundation-Blue-neural-B30, #ebedf0);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(
    --Color-Foundation-Grey-White,
    var(--Backgrounds-Primary, #fff)
  );
  font-family: "DM Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 107.143% */
  letter-spacing: 0.56px;
  text-transform: uppercase;
}
.heading-info {
  color: var(--title--text-color, #f5f5f5);
  text-align: justify;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
  letter-spacing: 0.16px;
  max-width: 678px;
}
.about-heading-container {
  min-height: 560px;
}
.mission {
  display: flex;
  max-width: 883px;
  width: 100%;
  height: auto;
  border-radius: 5px 0px 0px 5px;
  background: var(--Dark-Black, #162b3e);
  padding: 42px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}
.mission h3 {
  color: var(--title--text-color, #f5f5f5);
  text-align: center;
  position: relative;
  font-family: "DM Sans";
  font-size: 18px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.18px;
}
.mission p {
  color: var(--title--text-color, #f5f5f5);

  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
  letter-spacing: 0.16px;
}
.mission-img-ctn {
  width: 212px;
  height: 100%;
  padding: 70px 61px;
  align-items: center;
  gap: 10px;
  border-radius: 0px 5px 5px 0px;
  background: var(--Backgrounds-Primary, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 166, 254, 0.25);
}
