.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-link {
  color: #61dafb;
}
.recent-news-container {
  width: 269px;
  height: 367.711px;
  border-radius: 0; /* Reset default border-radius */
  position: relative;
  overflow: hidden;
}

/* Top 10% part */
.recent-news-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background: inherit; /* Inherit background from parent div */
  border-top-left-radius: 50px; /* Adjust as needed */
  border-top-right-radius: 50px; /* Adjust as needed */
}

/* Bottom 10% part */
.recent-news-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background: inherit; /* Inherit background from parent div */
  border-bottom-left-radius: 50px; /* Adjust as needed */
  border-bottom-right-radius: 50px; /* Adjust as needed */
}
